import gsap from "gsap";


// -----------------------------------------------------------------------------
//! Homepage Parallax
// -----------------------------------------------------------------------------

$(".servicesHero .hero-text").mousemove(function(e) {
	parallaxIt(e, ".hero-graphic-bg-background", -10);
	parallaxIt(e, ".services-triangle", -25);
	parallaxIt(e, ".services-moon", -20);
});

function parallaxIt(e, target, movement) {
	var $this = $(".servicesHero .hero-text");
	var relX = e.pageX - $this.offset().left;
	var relY = e.pageY - $this.offset().top;
	
	gsap.to(target,{
		duration: 1,
		x: (relX - $this.width() / 2) / $this.width() * movement,
		y: (relY - $this.height() / 2) / $this.height() * movement,
		
	});
}

