import supportsWebP from 'supports-webp';
// supportsWebP is a Promise
 
supportsWebP.then(supported => {
    if (supported) {
        $('body').addClass('webp');
    } else {
        $('body').addClass('no-webp');
    }
});
 