import gsap from "gsap";

function onMouseMove( e ) {
	var follower = this.querySelector('.circle');
    gsap.to(follower, {
	    duration: 0.5,
        x: e.offsetX,
        y: e.offsetY,
        ease:"power4.out"
    });
};
	
function init() {
	document.querySelectorAll('.cursor-wrap').forEach(item => {
		item.addEventListener('mousemove', onMouseMove)
	});
};


document.addEventListener("DOMContentLoaded", function(event) {
    window.onload = function() {
        init();
     };
});



	
