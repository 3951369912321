import './components/parallax';
import './components/smoothScroll';
import './components/sliders';
import './components/lazyLoad';
import './components/aboutAtmo';
import './components/hamburger';
import './components/homeAnimation';
import './components/servicesHero';
import './components/cursor';
import './components/scrollReveal';
import './components/formSwitcher';
import './components/formLabels';
import './components/formUpload';
import './components/formAjax';
import './components/grid';
import './components/error';
import './components/focus';
import './components/konami';
import './components/webp';
