import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import gsap from "gsap";



$(document).ready(function() {

	var targetElement = $('.nav-inner');
	$('html').on('click', '.hamburger', function(e) {
		$( e.delegateTarget ).toggleClass('nav-open');
		$(this).toggleClass('is-active'); 
		$('.nav').fadeToggle(200);
		var navIn = gsap.timeline();
		if($(this).hasClass('is-active')){
			disableBodyScroll(targetElement);
			navIn
				.to('.nav-inner > a', {duration: 0.3, x:'180', opacity:1, ease: "power4.in", stagger: 0.1}, '+-0.1')
				.to('.nav-mobile', {duration: 0.6, opacity:1, y: '0', ease: "power4.out"}, "mobileNav+=0.2")
			;
		}else {
			enableBodyScroll(targetElement);
			navIn
				.set('.nav-inner > a', {x:'-180', opacity:0}, '+=0.2')
				.set('.nav-mobile', {y:'40', opacity:0}, '+=0.2')
			;
		}
	});	
	

});

