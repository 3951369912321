import lozad from 'lozad'


//using https://github.com/ApoorvSaxena/lozad.js

var observer = lozad('.lozad', {
    rootMargin: '500px 0px', // syntax similar to that of CSS Margin
    loaded: function(el) {
        // Custom implementation on a loaded element
        el.classList.add('loaded');
    }
});
observer.observe();