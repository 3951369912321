import { gsap, MotionPathPlugin } from "gsap/all";

gsap.registerPlugin(MotionPathPlugin);


// -----------------------------------------------------------------------------
//! About Page Atmo
// -----------------------------------------------------------------------------
if($('#atmo-moon').length){	
	
	
	var tlBounce = gsap.timeline({repeat: -1, yoyo:false});
	
		
	var curve = [{x:0, y:0}, {x:10,y:-10},{x:20,y:-20},{x:30,y:0},{x:20,y:10},{x:10,y:10},{x:0,y:0},{x:0, y:0},{x:-10,y:-10},{x:-20,y:-10},{x:-30,y:0},{x:-20,y:10},{x:-10,y:10},{x:0,y:0}];
	
	tlBounce
		.to('#atmo-moon', {
			duration: 16,
			motionPath: {
				path:curve,
				curviness: 1
			},
			ease: "none"
		})
	;
	
}