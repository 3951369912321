$('a.back-to-top').on('click', function(e) {
	var id = $(this).attr("href");
    var offset = 0;
    var target = $(id).offset().top - offset;

    $('html, body').animate({scrollTop:target}, 1000, function() {
	    $('.back-to-top').css('transform', 'scale(0.1) rotate(180deg)');
		$('.back-to-top').animate({
			'opacity': '0'
		}, 300, function(){
			$('.back-to-top').removeClass('is-fixed').attr('style', '');
		})
  	});
  	$(this).addClass('is-fixed');

    event.preventDefault();
});



$('.scroll').on('click', function(e) {
	var id = $(this).attr("href");
    var offset = 160;
    var target = $(id).offset().top - offset;
    $("html, body").animate({
        scrollTop:target
    }, 1000);
    e.preventDefault();    
});

