$(document).ready(function() {

	
	$('.form-column').on('focus', '.form-input', function(e) {
		$( e.delegateTarget ).addClass('is-focused'); 
	});
	

	$('.form-column').on('blur', '.form-input', function(e) {
		var fieldValue = $(this).val();
		if(fieldValue === ""){
			$( e.delegateTarget ).removeClass('is-focused');
			$( e.delegateTarget ).removeClass('is-filled'); 
		}
	});
			
	$('.form-input').each(function (index, value){
		var fieldValue = $(this).val();
		if(fieldValue != ""){
			$(this).parents('.form-column').addClass('is-filled');
		}
	});
	

});