import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import slick from 'slick-carousel';

$(document).ready(function(){
   
	var $status = $('.slider-numbers');

	$('.slider-wrap').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
	    var i = (currentSlide ? currentSlide : 0) + 1;
	    if(i <= 9) {
		    i = '0' + i;
	    }
	    var totalSlides = slick.slideCount;
	    if (totalSlides <= 9) {
		    totalSlides = '0' + totalSlides;
	    }
	    $(this).parent().find('.slider-numbers').html('<span class="current-num">' + i + '</span><span class="total-num">' + totalSlides + '</span>');
	});


	$('.deepDive').on('click', '.diver', function(e) {
		//Scroll to top of section when diving down.
		$('html').addClass('nav-open');
		
		var id = $(e.delegateTarget);
	  	//var targetElement = id.find('.slider-inner-wrap');
	  	//disableBodyScroll(targetElement);
	  	id.find('.slider').slick({
		    autoplay: false,
		    infinite: true,
		    dots: false,
		    fade: true,
		    speed: 0,
		    adaptiveHeight: true,
		    prevArrow: $('.slider-previous'),
			nextArrow: $('.slider-next')
		});
	  	
	  	$(e.delegateTarget).find('.slider-wrap').fadeIn(200).addClass('is-visible');
	  	var slideno = $(this).data('slide');
	  	$(e.delegateTarget).find('.slider').show();
	    $(e.delegateTarget).find('.slider').slick('slickGoTo', slideno - 1);
	});	
	
	
	// Handle ESC key (key code 27)
		document.addEventListener('keyup', function(e) {
		    if (e.keyCode == 27) {
		        closeSlider();
		    }
		});
		
	function closeSlider(){
		$('.slider-wrap.is-visible').find('.slider').hide();
		$('.slider-wrap.is-visible').find('.slider').slick('unslick');
	  	$('.slider-wrap.is-visible').fadeOut(200).removeClass('is-visible');
	  	//enableBodyScroll();
	  	$('html').removeClass('nav-open');

	  	
	}

	$('.deepDive').on('click', '.slider-close', function(e) {
	  	closeSlider();
	});	
	
	$(".slider-wrap")
		.on("click", function(e) {
	       closeSlider();
	    })
	    .on("click", ".slider-close", function( e ) {
	       closeSlider();
	    })
	    .on("click", ".slider-arrows", function(e) {
	        e.stopPropagation();
		})
		.on("click", ".slider-numbers", function(e) {
	        e.stopPropagation();
		})
	    .on("click", ".slider", function(e) {
	        e.stopPropagation();
		})
	;

	
	
  
  
});
