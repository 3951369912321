import { gsap, MotionPathPlugin } from "gsap/all";

gsap.registerPlugin(MotionPathPlugin);


// -----------------------------------------------------------------------------
//! About Page Atmo
// -----------------------------------------------------------------------------
if($('body').hasClass('who-we-are')){	
	var tlAtmo = gsap.timeline();
	var tlBounce = gsap.timeline({repeat: -1, yoyo:false, delay: 1.95});
	
	var atmo = document.getElementsByClassName("hero-atmo");	
	var atmoIconWrap = document.getElementsByClassName("atmo-icon-wrap");
	var atmoIcon = document.getElementsByClassName("atmo-icon");
	var trail = document.getElementsByClassName("trail");
	
	tlAtmo
		.to(atmoIconWrap, {duration:0.5, opacity: 1, ease:"power2.out"}, "atmo_in+=0.5")
		.to(atmoIconWrap,{duration: 2, top:0, ease:"back.out(1)"}, "atmo_in+=0.5")
		.to(trail, {duration:0.3, opacity:0, ease: "power2.in"}, "atmo_in+=1")
		.to(atmoIcon, {duration: 0.75, rotation:0, ease:"back.out.(2.5)"}, "atmo_in+=1.25")
	;
	
	var curve = [{x:0, y:0}, {x:30,y:-30},{x:60,y:-30},{x:90,y:0},{x:60,y:30},{x:30,y:30},{x:0,y:0},{x:0, y:0},{x:-30,y:-30},{x:-60,y:-30},{x:-90,y:0},{x:-60,y:30},{x:-30,y:30},{x:0,y:0}];
	
	tlBounce
		.to(atmoIcon, {
			duration: 16,
			motionPath: curve,
			ease: "none"
		})
	;
	
}