jQuery(document).ready(function ($) {

	$('.form-input[type="file"]').each(function () {
		var $input = $(this),
			$label = $input.next('label'),
			labelVal = $label.html();

		var fileName = '';

		if (this.files && this.files.length > 1) {
			fileName = this.files.length + ' files selected';
		} else if (this.value) {
			fileName = this.value.split('\\').pop();
		}

		if (fileName) {
			$label.find('span').addClass('is-filled').html(fileName);
		} else {
			$label.html(labelVal);
		}

		$input.on('change', function (e) {
			var fileName = '';

			if (this.files && this.files.length > 1) {
				fileName = `${this.files.length} files selected`;
			} else if (e.target.value) {
				fileName = e.target.value.split('\\').pop();
			}

			if (fileName) {
				$label.find('span').addClass('is-filled').html(fileName);
			} else {
				$label.html(labelVal);
			}
		});
	});

	// Allow file uploads to be accessible
	// function a11yClick(event) {
	// 	if (event.type === 'click') {
	// 		return true;
	// 	} else if (event.type === 'keypress') {
	// 		var code = event.charCode || event.keyCode;

	// 		if ((code === 32) || (code === 13)) {
	// 			return true;
	// 		}
	// 	} else {
	// 		return false;
	// 	}
	// }

	// $('.form-fauxUpload').on('click keypress', function () {
	// 	if (a11yClick(event) === true) {
	// 		$(this).prev().click();
	// 	}
	// });

});