import imagesLoaded from 'imagesloaded';
import ScrollMagic from 'scrollmagic'


var workController = new ScrollMagic.Controller();

function resizeGridItem(item){
   var  grid = $(".workGrid")[0];
   var rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
   var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
   var rowSpan = Math.ceil((item.querySelector('.workGrid-item-inner').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
   item.style.gridRowEnd = "span "+rowSpan;
}


function resizeAllGridItems(){
   var allItems = $(".workGrid-item");
   for(var x=0;x<allItems.length;x++){
      resizeGridItem(allItems[x]);
   }
   
   $('.workGrid-item').each(function () {
	    var currentWork = this;
	    new ScrollMagic.Scene({
			triggerElement: currentWork,
			triggerHook: 0.8, 
			reverse: false
		}).on('start', function () {
			var work = this.triggerElement()
	        $(work).addClass('is-visible');
	    }).addTo(workController);
	});

   
   
}


window.onload = resizeAllGridItems();
window.addEventListener("resize", resizeAllGridItems);



function resizeInstance(instance){
   var item = instance.elements[0];
   resizeGridItem(item);
}

var allItems = $(".workGrid-item");
for(var x=0;x<allItems.length;x++){
   imagesLoaded( allItems[x], resizeInstance);
}





