import ScrollMagic from 'scrollmagic'
import { gsap } from "gsap";

// init controller
var logoController = new ScrollMagic.Controller();
var iconController = new ScrollMagic.Controller();


//About Page Logos
$('.logo-grid-box').each(function () {
    var currentLogo = this;
    new ScrollMagic.Scene({
		triggerElement: currentLogo,
		triggerHook: 0.8, 
		reverse: false
	}).on('start', function () {
		var logo = this.triggerElement()
        $(logo).addClass('is-visible');
    }).addTo(logoController);
});


//Services Page

$('.serviceBox-icon').each(function () {
    var currentIcon = this;
    new ScrollMagic.Scene({
		triggerElement: currentIcon,
		triggerHook: 0.8, 
		reverse: false
	}).on('start', function () {
		var icon = this.triggerElement()
        $(icon).addClass('in-screen');
    }).addTo(iconController);
});


$('.serviceDo-block-left-icon').each(function () {
    var currentIcon = this;
    new ScrollMagic.Scene({
		triggerElement: currentIcon,
		triggerHook: 0.8, 
		reverse: false
	}).on('start', function () {
		var icon = this.triggerElement()
        $(icon).addClass('in-screen');
    }).addTo(iconController);
});

