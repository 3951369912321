jQuery(document).ready(function($) {

	$('form').on('submit',  function(e) {
		$(this).parent().addClass('is-loading');
	});


	// if ( $('section.form').length ) {
	// 	var form1 = document.querySelector('#form-panel-1');
    //     var form2 = document.querySelector('#form-panel-2');
    //     var form3 = document.querySelector('#form-panel-3');

    //     $.ajax({
	// 		url: '/dynamic?form=discussAProject',
	// 		type: 'get',
	// 		dataType: 'json',
	// 		success: function (response) {
	// 			form1.querySelector('input[name=formHash]').value = response.hash;

	// 			var honeypot = response.honeypot;
	// 			var honeypotInput = form1.querySelector('input[name^="freeform_form_handle"]');
	// 			honeypotInput.setAttribute('id', honeypot.name);
	// 			honeypotInput.setAttribute('name', honeypot.name);
	// 			honeypotInput.value = honeypot.hash;

	// 			var csrf = response.csrf;
	// 			form1.querySelector('input[name=' + csrf.name + ']').value = csrf.value;
	// 		},
    //     });

	// 	$.ajax({
	// 		url: '/dynamic?form=work',
	// 		type: 'get',
	// 		dataType: 'json',
	// 		success: function (response) {
	// 			form2.querySelector('input[name=formHash]').value = response.hash;

	// 			var honeypot = response.honeypot;
	// 			var honeypotInput = form2.querySelector('input[name^="freeform_form_handle"]');
	// 			honeypotInput.setAttribute('id', honeypot.name);
	// 			honeypotInput.setAttribute('name', honeypot.name);
	// 			honeypotInput.value = honeypot.hash;

	// 			var csrf = response.csrf;
	// 			form2.querySelector('input[name=' + csrf.name + ']').value = csrf.value;
	// 		},
    //     });

	// 	$.ajax({
	// 		url: '/dynamic?form=chat',
	// 		type: 'get',
	// 		dataType: 'json',
	// 		success: function (response) {
	// 			form3.querySelector('input[name=formHash]').value = response.hash;

	// 			var honeypot = response.honeypot;
	// 			var honeypotInput = form3.querySelector('input[name^="freeform_form_handle"]');
	// 			honeypotInput.setAttribute('id', honeypot.name);
	// 			honeypotInput.setAttribute('name', honeypot.name);
	// 			honeypotInput.value = honeypot.hash;

	// 			var csrf = response.csrf;
	// 			form3.querySelector('input[name=' + csrf.name + ']').value = csrf.value;
	// 		},
    //     });
	// }
});