import { gsap } from "gsap/all";
import Konami from 'konami';

// -----------------------------------------------------------------------------
//! Devs - shhhhhh....this is our secret
// -----------------------------------------------------------------------------


$(document).ready(function() {


	var atmo_egg = new Konami(function() { 
		$('.atmo-fall').show();
		var e = $('.bouncy-atmo');
	    for (var i = 0; i < 17; i++) {
	      e.clone().addClass('clone-' + i).insertAfter(e);
	    }
	    $('.bouncy-atmo').first().addClass('clone-0');
			
		var konami = gsap.timeline();
		
		konami
			.to($('.atmo-fall'), {duration:2, scale:1, y: '-60vh', ease:"power2.out"})
			.to($('.clone-0'), {duration:0.6, scale:1.6,yoyo: true, repeat: 6, ease:"power2.in"}, "pulse-=0.2")
			.to($('.clone-0'), {duration:0.4, rotate:720, ease:"power2.in"}, "spin-=0.4")
			.to($('.clone-0'), {duration:0.1, opacity:0, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-1'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-1'), {duration:0.8, x:'50vw', y:'-20vh', ease:"power2.out"}, "splode-=0.1")	
			.to($('.clone-2'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-2'), {duration:0.8, x:'-50vw', y:'-20vh', ease:"power2.out"}, "splode-=0.1")	
			.to($('.clone-3'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-3'), {duration:0.8, x:'-50vw',y:'-50vh', ease:"power2.out"}, "splode-=0.1")	
			.to($('.clone-4'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-4'), {duration:0.8, x:'50vw',y:'-50vh', ease:"power2.out"}, "splode-=0.1")	
			.to($('.clone-5'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-5'), {duration:0.8, y:'-50vh', ease:"power2.out"}, "splode-=0.1")
			
			.to($('.clone-7'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-7'), {duration:0.8, x:'50vw', y:'20vh', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-6'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-6'), {duration:0.8, x:'-50vw', y:'20vh', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-8'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-8'), {duration:0.8, x:'50vw', y:'50vh', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-9'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-9'), {duration:0.8, x:'-50vw', y:'50vh', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-10'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-10'), {duration:0.8, y:'50vh', ease:"power2.out"}, "splode-=0.1")	
			.to($('.clone-11'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-11'), {duration:0.8, x:'50vw', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-12'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-12'), {duration:0.8, x:'-50vw', ease:"power2.out"}, "splode-=0.1")
		
			.to($('.clone-13'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-13'), {duration:0.8, x:'25vw',y:'-50vh', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-14'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-14'), {duration:0.8, x:'-25vw',y:'-50vh', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-15'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-15'), {duration:0.8, x:'25vw',y:'50vh', ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-16'), {duration:0.1, opacity:1, ease:"power2.out"}, "splode-=0.1")
			.to($('.clone-16'), {duration:0.8, x:'-25vw',y:'50vh', ease:"power2.out"}, "splode-=0.1")		
			.to($('.bouncy-atmo'), {duration:0.3, opacity:0, ease:"power2.in"}, "out-=0.6")	
				
		;
		
		konami.eventCallback("onComplete", function() {
		  $('.atmo-fall').hide();
		});
	
	
	});



});
