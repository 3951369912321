$(document).ready(function () {

	// Open Controls

	function toggleControls() {
		$('.form-controls').toggleClass('form-panel-selection-open');
		if ($('.form-controls').hasClass('form-panel-selection-open')) {
			$('.form-panel-selection').find('button').attr('tabindex', 0);
		} else {
			$('.form-panel-selection').find('button').attr('tabindex', -1);
		}
	}

	$('.form-controls').on('click', '.form-panel-switcher', function (e) {
		toggleControls();
	});

	// Toggle Panel

	$('.form-left').on('click', '.form-panel-option', function (e) {

		// deactivate the current panel
		$('.form-panel.is-active').removeClass('is-active').hide();

		// activate the new panel
		var targetPanel = $(this).data('panel');
		$(e.delegateTarget).find('#' + targetPanel).fadeIn(400).addClass('is-active');

		// change the controls
		var targetText = $(this).text();
		$('.form-panel-current-text').text(targetText);
		$('.form-panel-option.is-active').removeClass('is-active');
		$(this).addClass('is-active');

		// close controls
		toggleControls();
	});

});