import gsap from "gsap";

$(document).ready(function() {

// -----------------------------------------------------------------------------
//! Homepage Parallax
// -----------------------------------------------------------------------------

	$("body.home").mousemove(function(e) {
		parallaxIt(e, ".splash-background-inner", -40);
		parallaxIt(e, ".splash-mountain-inner", -80);
		parallaxIt(e, ".splash-foreground-inner", -40);
	});
	
	function parallaxIt(e, target, movement) {
		var $this = $("body.home");
		var relX = e.pageX - $this.offset().left;
		gsap.to(target,{
			duration: 1,
			x: (relX - $this.width() / 2) / $this.width() * movement,
		});
	}


// -----------------------------------------------------------------------------
//! Homepage Text
// -----------------------------------------------------------------------------

	if($('body').hasClass('home')) {
		var tlHomeText = gsap.timeline();
		tlHomeText
			.to(".splash-title.is-background", {duration:1, opacity: 1, y:-120, ease:"power2.out"}, "text_in+=0.5")
			.to(".splash-title.is-foreground", {duration: 1, opacity: 1, ease:"power2.out"}, "text_in+=1.7")
			.to(".splash-subtitle", {duration: 1, opacity: 1, y:-120, ease:"power2.out"}, "subtitle_in-=0.2")
		;
	}
	


// -----------------------------------------------------------------------------
//! CTA Arrows
// -----------------------------------------------------------------------------

	if($('body').hasClass('home')) {
		var tlHomeArrows = gsap.timeline({repeat:-1});
		tlHomeArrows
			.staggerTo(".explore-arrow", 0.8, { opacity:1, ease:"power4.out"}, 0.2)
			.staggerTo(".explore-arrow", 0.8, { opacity:0, ease:"power4.in"}, 0.2, "-=1")
		;
	}


// -----------------------------------------------------------------------------
//! Logos
// -----------------------------------------------------------------------------
		if($('.logos-grid-wrapper').length){
			
				
			
				
				
			function fadeToggle() {
				$('.logos-grid-wrapper.is-visible').hide();
				$('.logos-grid-wrapper.is-hidden').fadeIn(1000);
		   		$('.logos-grid-wrapper').toggleClass('is-hidden').toggleClass('is-visible');
			}
			setInterval(fadeToggle, 14000);
			
		}
		
		

});
